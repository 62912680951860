/* eslint-disable max-len */
import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Form from '@amzn/awsui-components-react/polaris/form';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Input from '@amzn/awsui-components-react/polaris/input';
import ResponsiveContentLayout from '../Layout/ResponsiveContentLayout';
import TacAuthenticationSuccess from '../Alerts/TacAuthenticationSuccess';
import FailureAlert from '../Alerts/FailureAlert';
import {REQUIRED_PIN_LENGTH} from '../../utils/constants';
import {redirectToAuthorizeResume} from '../../handlers/Common/AuthorizeResumeHandler';
import TacInput from '../TacInput/TacInput';
import {isMobile} from 'react-device-detect';
import AuthenticationFailureAutoLogout from '../Alerts/AuthenticationFailureAutoLogout';
import {UserError} from '../../utils/userError';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';
import {handleClientSideMetrics} from '../../handlers/Common/ClientMetricsHandler';
import {ClientMetricsAttributesEnum, ClientMetricsCodesEnum} from '../../utils/ClientMetricsCodes';

/* eslint-disable @typescript-eslint/no-var-requires */
const pinIcon = require('../../assets/images/pinIcon.svg').default;

interface TacAndPinInputProps {
    onSubmit: (pin: string, tac: string) => Promise<boolean>;
    onBack: () => void;
    additionalLoginOptionsAllowed: boolean;
}

const TacAndPinInput = (props: TacAndPinInputProps) => {
  const bundle = useAuthgardBundle();

  const location = useLocation();

  const [pin, setPin] = useState('');
  const [tac, setTac] = useState('');
  const [pinError, setPinError] = useState('');
  const [alertId, setAlertId] = useState('');
  const [tacAndPinSuccess, setTacAndPinSuccess] = useState<boolean|undefined>(undefined);
  const [retryLimit, setRetryLimit] = useState<number>(3);
  const [autoLogout, setAutoLogout] = useState<boolean>(false);
  const requestId = new URLSearchParams(window.location.search).get('request_id') || location.state?.requestId;
  const [errorCode, setErrorCode] = useState<string | null>(null);

  const submitTacAndPin = async () => {
    try {
      const success = await props.onSubmit(pin, tac);
      if (success) {
        setTacAndPinSuccess(true);
        redirectToAuthorizeResume(requestId);
      } else {
        setRetryLimit(retryLimit - 1);
        setAlertId('tac_authentication_failure');
        setTacAndPinSuccess(false);
        window.scrollTo(0, 0); // Scroll to top on hand scanners to reveal error
        handleReset();
      }
    } catch (error) {
      if (error instanceof UserError) {
        handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
            ClientMetricsCodesEnum.USER_TAC_ERROR);
        setErrorCode(ClientMetricsCodesEnum.USER_TAC_ERROR);
        setRetryLimit(retryLimit - 1);
        setAlertId('tac_authentication_' + error.name);
        setTacAndPinSuccess(false);
        window.scrollTo(0, 0); // Scroll to top on hand scanners to reveal error
        handleReset();
      }
    }
  };

  useEffect(() => {
    if (retryLimit == 0) setAutoLogout(true);
  }, [retryLimit]);

  const handleReset = () => {
    setPin('');
    setTac('');
  };

  useEffect(() => {
    if (pin) {
      const weakPasswordExp = new RegExp('^(?!(\\d)\\1{5}|012345|123456|234567|345678|456789|567890|098765|987654|876543|765432|654321|543210)\\d{6}$', 'gm');
      const isPasswordWeak = weakPasswordExp.test(pin);

      if (pin.length !== REQUIRED_PIN_LENGTH) {
        setPinError(bundle.getMessage('pin_length_error'));
      } else if (!isPasswordWeak) {
        setPinError(bundle.getMessage('pin_weak_error'));
      } else {
        setPinError('');
      }
    } else {
      setPinError('');
    }
  }, [pin]);

  const handlePinChange = (event:any) => {
    const newPin = event.detail.value.slice(0, REQUIRED_PIN_LENGTH);
    setPin(newPin);
  };

  // Enable submission when clicking Enter button
  const handleKeyDown = async (event:any) => {
    if (event.detail.key === 'Enter' && pinError == '' && pin != '') {
      await submitTacAndPin();
    }
  };

  let authenticationAlert;
  if (autoLogout) {
    authenticationAlert = <AuthenticationFailureAutoLogout/>;
  } else if (tacAndPinSuccess !== undefined) {
    const headerId = alertId + '_title';
    const messageId = alertId + '_description';
    authenticationAlert = tacAndPinSuccess ? <TacAuthenticationSuccess/> :
     <FailureAlert headerId={headerId} dataId={alertId} messageId={messageId} errorCode={errorCode}/>;
  }

  const showAuthForm = !autoLogout && !tacAndPinSuccess;

  const header: JSX.Element = <Header
    variant="h1"
    description={bundle.getMessage('tac_authentication_title_description')}
  >
    {bundle.getMessage('tac_authentication_title')}
  </Header>;

  const footer: JSX.Element = <div data-css={isMobile ? 'mobile-col' : 'row'}>
    {props.additionalLoginOptionsAllowed && (
      <Button
        onClick={props.onBack}
        fullWidth={isMobile}
      >
        {bundle.getMessage('authentication_back')}
      </Button>
    )}
    {tac != '' &&
        <Button
          formAction="none"
          onClick={handleReset}
          fullWidth={isMobile}
        >
          {bundle.getMessage('tac_pin_clear_button')}
        </Button>}
    {tac != '' && <Button
      variant="primary"
      disabled={pinError != '' || pin == '' || tac == ''}
      onClick={submitTacAndPin}
      fullWidth={isMobile}
    >
      {bundle.getMessage('submit_button')}
    </Button>}
  </div>;

  return (
    <ResponsiveContentLayout>
      <SpaceBetween size='l'>
        {authenticationAlert}
        {showAuthForm && (
          <Container header={header} footer={footer}>
            <Form errorText={pinError}>
              <SpaceBetween direction="vertical" alignItems="center" size="l">
                {tac == '' && <TacInput setTac={(tac) => setTac(tac)} tac={tac}/>}
                {tac != '' && <FormField
                  label={
                    <SpaceBetween direction="vertical" alignItems="center" size="xxxs">
                      <img src={pinIcon} width={40} height={40} alt="security key"/>
                      {bundle.getMessage('pin_label_enter')}
                    </SpaceBetween>
                  }
                >
                  <Input
                    autoFocus
                    value={pin}
                    type="number"
                    onChange={handlePinChange}
                    onKeyDown={handleKeyDown}
                    placeholder="012345"
                    data-testid="pin-input"
                    inputMode="numeric"
                    data-css="password"
                  />
                </FormField>}
              </SpaceBetween>
            </Form>
          </Container>
        )}
      </SpaceBetween>
    </ResponsiveContentLayout>
  );
};

export default TacAndPinInput;
