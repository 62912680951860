import React, {useEffect} from 'react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import ResponsiveContentLayout from '../../components/Layout/ResponsiveContentLayout';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';
import {handleClientSideMetrics} from '../../handlers/Common/ClientMetricsHandler';
import {ClientMetricsAttributesEnum, ClientMetricsCodesEnum} from '../../utils/ClientMetricsCodes';

const NotFound = () => {
  const bundle = useAuthgardBundle();
  const errorCode = ClientMetricsCodesEnum.NOT_FOUND;

  useEffect(() => {
    handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR, errorCode);
  }, []);

  const header = `${errorCode}: ${bundle.getMessage('app_not-found_title')}`;

  return (
    <ResponsiveContentLayout>
      <Alert
        statusIconAriaLabel="Error"
        type="error"
        header={header}
      >
        {bundle.getMessage('app_not-found_description',
            {defaultMessage: 'This page could not be found'},
        )}
      </Alert>
    </ResponsiveContentLayout>
  );
};

export default NotFound;
