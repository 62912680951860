import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';
import React, {useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {applyDensity, Density} from '@amzn/awsui-global-styles';
import InternalServerError from '../../components/Alerts/InternalServerError';
import TcpInput from '../../components/RegCodeInput/RegCodeInput';
import {fetchUserDetails} from '../../handlers/Common/UserDetailsHandler';
import {fetchRegistrationCsrfToken} from '../../handlers/Common/CsrfHandlers';
import {handleRegCode} from '../../handlers/RegCode/RegCodeHandlers';
import {isLocalEnv} from '../../utils/envHelper';
import ResponsiveContentLayout from '../../components/Layout/ResponsiveContentLayout';
import {handleClientSideMetrics} from '../../handlers/Common/ClientMetricsHandler';
import {ClientMetricsAttributesEnum, ClientMetricsCodesEnum} from '../../utils/ClientMetricsCodes';

const RegCode = () => {
  const bundle = useAuthgardBundle();
  const [userName, setUserName] = useState<string>('');
  const [serverError, setServerError] =
    useState<{isError: boolean, errorCode: string}>({isError: false, errorCode: ''});
  const [csrfToken, setCsrfToken] = useState<string>('');

  useEffect(() => {
    document.title = bundle.getMessage('registration_document_title');

    if (isMobile) {
      applyDensity(Density.Compact);
    }

    if (!isLocalEnv) {
      handleCsrf();
      handleUserDetails();
    } else {
      setCsrfToken('testCsrfToken');
      setUserName('test user');
    }
  }, []);

  const handleCsrf = async () => {
    const token = await fetchRegistrationCsrfToken();
    if (token != '') {
      setCsrfToken(token);
    } else {
      handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
          ClientMetricsCodesEnum.CSRF_SERVER_ERROR);
      setServerError({isError: true, errorCode: ClientMetricsCodesEnum.CSRF_SERVER_ERROR});
    }
  };

  const handleUserDetails = async () => {
    const userDetails = await fetchUserDetails();
    if (userDetails != null) {
      setUserName(userDetails.userName);
    } else {
      handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
          ClientMetricsCodesEnum.USER_DETAILS_SERVER_ERROR);
      setServerError({isError: true, errorCode: ClientMetricsCodesEnum.USER_DETAILS_SERVER_ERROR});
    }
  };

  const onSubmit = (regCode: string): Promise<boolean> => {
    return handleRegCode(regCode, csrfToken);
  };

  return (
    <ResponsiveContentLayout>
      {serverError.isError ?
        <InternalServerError errorCode={serverError.errorCode} /> :
        <TcpInput onSubmit={onSubmit} userName={userName}/>
      }
    </ResponsiveContentLayout>
  );
};

export default RegCode;
