import React from 'react';
import {Alert} from '@amzn/awsui-components-react';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';
import {ErrorAlertProps} from '../../utils/ErrorTypes';

const InternalServerError: React.FC<ErrorAlertProps> = ({errorCode}) => {
  const bundle = useAuthgardBundle();

  const header = errorCode ?
    `${errorCode}: ${bundle.getMessage('internal_server_error_title')}` :
    bundle.getMessage('internal_server_error_title');

  return (
    <Alert
      statusIconAriaLabel="Error"
      type="error"
      data-testid="internal-server-error"
      header={header}
    >
      {bundle.getMessage('internal_server_error_description')}
    </Alert>
  );
};

export default InternalServerError;
