import React, {useEffect} from 'react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import ResponsiveContentLayout from '../../components/Layout/ResponsiveContentLayout';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';
import {
  REGISTRATION_ERROR_PAGE_CODE_MAPPING,
  BACKEND_TO_FRONTEND_REGISTRATION_ERROR_MAPPING,
} from '../../utils/constants';
import {handleClientSideMetrics} from '../../handlers/Common/ClientMetricsHandler';
import {ClientMetricsAttributesEnum, ClientMetricsCodesEnum} from '../../utils/ClientMetricsCodes';

const AuthenticationErrorPage = () => {
  const bundle = useAuthgardBundle();
  const backendErrorCode = new URLSearchParams(window.location.search).get('error_code') ?? '';
  const frontendErrorCode = BACKEND_TO_FRONTEND_REGISTRATION_ERROR_MAPPING[backendErrorCode] || 'ISE100';
  const titleMessage = REGISTRATION_ERROR_PAGE_CODE_MAPPING[backendErrorCode]?.['title'];
  const descriptionMessage = REGISTRATION_ERROR_PAGE_CODE_MAPPING[backendErrorCode]?.['description'];

  useEffect(() => {
    handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
        ClientMetricsCodesEnum.REGISTRATION_ERROR_PAGE);
  }, []);

  const header = frontendErrorCode ?
    `${frontendErrorCode}: ${bundle.getMessage(titleMessage ?? 'internal_server_error_title')}` :
    bundle.getMessage(titleMessage ?? 'internal_server_error_title');

  return (
    <ResponsiveContentLayout>
      <Alert
        statusIconAriaLabel="Error"
        type="error"
        header={header}
      >
        {bundle.getMessage(descriptionMessage ?? 'internal_server_error_description')}
      </Alert>
    </ResponsiveContentLayout>
  );
};

export default AuthenticationErrorPage;
