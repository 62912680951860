/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import Form from '@amzn/awsui-components-react/polaris/form';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Input from '@amzn/awsui-components-react/polaris/input';
import ResponsiveContentLayout from '../Layout/ResponsiveContentLayout';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';
import {UserError} from '../../utils/userError';
import FailureAlert from '../Alerts/FailureAlert';
import {useNavigate} from 'react-router-dom';
import RegistrationFailureAutoLogout from '../Alerts/RegistrationFailureAutoLogout';
import {handleRegLogout} from '../../handlers/Common/LogoutHandlers';
import {REG_PAGE_TIMEOUT_IN_MS} from '../../utils/constants';
import {handleClientSideMetrics} from '../../handlers/Common/ClientMetricsHandler';
import {ClientMetricsAttributesEnum, ClientMetricsCodesEnum} from '../../utils/ClientMetricsCodes';

interface RegCodeInputProps {
  onSubmit: (regCode: string) => Promise<boolean>
}

interface UserDataProps {
  readonly userName: string;
}

const RegCodeInput = (props: RegCodeInputProps & UserDataProps) => {
  const [regCode, setRegCode] = useState('');
  const [regcodeInputError, setRegcodeInputError] = useState('');
  const [alertId, setAlertId] = useState('');
  const [regCodeSuccess, setRegCodeSuccess] = useState<boolean|undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [autoLogout, setAutoLogout] = useState<boolean>(false);
  const [retryLimit, setRetryLimit] = useState<number>(5); // Match backend validations
  const [errorCode, setErrorCode] = useState<string | null>(null);
  const bundle = useAuthgardBundle();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = bundle.getMessage('registration_document_title');

    // Start page timeout
    const pageTimeout = setTimeout(() => {
      handleRegLogout(true);
    }, REG_PAGE_TIMEOUT_IN_MS);

    // Clear timeout on component unmount
    return () => {
      clearTimeout(pageTimeout);
    };
  }, []);

  useEffect(() => {
    if (retryLimit == 0) setAutoLogout(true);
  }, [retryLimit]);

  const submitRegCode = async () => {
    try {
      setLoading(true);
      const success = await props.onSubmit(regCode);
      setLoading(false);
      if (success) {
        setRegCodeSuccess(true);
        navigate('../registration', {relative: 'path'});
      } else {
        setAlertId('reg_code_failure');
        setRegCodeSuccess(false);
        handleReset();
        setRetryLimit(retryLimit - 1);
        setErrorCode(ClientMetricsCodesEnum.REGCODE_VALIDATION_SERVER_ERROR);
        handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
            ClientMetricsCodesEnum.REGCODE_VALIDATION_SERVER_ERROR);
      }
    } catch (error) {
      if (error instanceof UserError) {
        setAlertId(error.name);
        setRegCodeSuccess(false);
        window.scrollTo(0, 0); // Scroll to top on hand scanners to reveal error
        handleReset();
        setRetryLimit(retryLimit - 1);
        setErrorCode(ClientMetricsCodesEnum.USER_REGCODE_ERROR);
        handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
            ClientMetricsCodesEnum.USER_REGCODE_ERROR);
      }
    }
  };

  const handleReset = () => {
    setRegCode('');
    setRegcodeInputError('');
  };

  const handleRegCodeChange = (event:any) => {
    const newRegCode = event.detail.value.slice(0, 6);
    if (newRegCode.length !== 6) {
      setRegcodeInputError(bundle.getMessage('reg_code_length_error'));
    } else {
      setRegcodeInputError('');
    }
    setRegCode(newRegCode);
  };

  let RegCodeAlert;
  if (autoLogout) {
    RegCodeAlert = <RegistrationFailureAutoLogout/>;
  } else if (regCodeSuccess !== undefined) {
    const headerId = alertId + '_title';
    const messageId = alertId + '_description';
    if (!regCodeSuccess) {
      RegCodeAlert = <FailureAlert headerId={headerId} dataId={alertId} messageId={messageId} errorCode={errorCode} />;
    }
  }

  const header: JSX.Element = <Header
    variant="h1"
    description={bundle.getMessage('reg_code_title_description')}
  >
    {bundle.getMessage('reg_code_title', {userName: props.userName})}
  </Header>;

  const footer: JSX.Element = <div style={{display: 'flex', justifyContent: 'end'}}>
    <SpaceBetween direction="horizontal" size="s" >
      <Button formAction="none" onClick={handleReset}>
        {bundle.getMessage('pin_clear_button')}
      </Button>
      <Button
        variant="primary"
        disabled={regCode == '' || regcodeInputError != ''}
        onClick={submitRegCode}
        loading={loading}
        data-testid="submit-regcode"
      >
        {bundle.getMessage('submit_button')}
      </Button>
    </SpaceBetween>
  </div>;

  return (
    <ResponsiveContentLayout>
      <SpaceBetween size='l'>
        {RegCodeAlert}
        {!regCodeSuccess && !autoLogout && (
          <Container header={header} footer={footer}>
            <Form errorText={regcodeInputError}>
              <SpaceBetween direction="vertical" alignItems="center" size="l">
                <FormField label={bundle.getMessage('reg_code_label_enter')}>
                  <Input
                    autoFocus
                    value={regCode}
                    type="number"
                    onChange={handleRegCodeChange}
                    placeholder="012345"
                    data-testid="regcode-input"
                    inputMode="numeric"
                    data-css="numeric"
                  />
                </FormField>
              </SpaceBetween>
            </Form>
          </Container>
        )}
      </SpaceBetween>
    </ResponsiveContentLayout>
  );
};

export default RegCodeInput;
