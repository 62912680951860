import React, {useEffect, useState} from 'react';
import {fetchRegistrationCsrfToken} from '../../handlers/Common/CsrfHandlers';
import PinInput from '../../components/PinInput/PinInput';
import {handlePinRegistration} from '../../handlers/Pin/PinRegistrationHandlers';
import {fetchIdentityPublicKey} from '../../handlers/Common/EncryptionPinHandlers';
import InternalServerError from '../../components/Alerts/InternalServerError';
import {isLocalEnv} from '../../utils/envHelper';
import {fetchUserDetails} from '../../handlers/Common/UserDetailsHandler';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';
import {handleRegLogout} from '../../handlers/Common/LogoutHandlers';
import {REG_PAGE_TIMEOUT_IN_MS} from '../../utils/constants';
import {handleClientSideMetrics} from '../../handlers/Common/ClientMetricsHandler';
import {ClientMetricsAttributesEnum, ClientMetricsCodesEnum} from '../../utils/ClientMetricsCodes';

const PinRegistration = () => {
  const [userName, setUserName] = useState<string>('');
  const [csrfToken, setCsrfToken] = useState<string>('');
  const [publicKey, setPublicKey] = useState<CryptoKey>();
  const [serverError, setServerError] =
    useState<{isError: boolean, errorCode: string}>({isError: false, errorCode: ''});
  const bundle = useAuthgardBundle();

  useEffect(() => {
    document.title = bundle.getMessage('registration_document_title');

    if (!isLocalEnv) {
      handlePublicKey();
      handleCsrf();
      userName == '' ? handleUserDetails() : null;
    }

    // Start page timeout
    const pageTimeout = setTimeout(() => {
      handleRegLogout(true);
    }, REG_PAGE_TIMEOUT_IN_MS);

    // Clear timeout on component unmount
    return () => {
      clearTimeout(pageTimeout);
    };
  }, []);

  const handlePublicKey = async () => {
    const key = await fetchIdentityPublicKey();
    if (key != undefined) {
      setPublicKey(key);
    } else {
      handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
          ClientMetricsCodesEnum.PIN_JWKS_SERVER_ERROR);
      setServerError({isError: true, errorCode: ClientMetricsCodesEnum.PIN_JWKS_SERVER_ERROR});
    }
  };

  const handleCsrf = async () => {
    const token = await fetchRegistrationCsrfToken();
    if (token != '') {
      setCsrfToken(token);
    } else {
      handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
          ClientMetricsCodesEnum.CSRF_SERVER_ERROR);
      setServerError({isError: true, errorCode: ClientMetricsCodesEnum.CSRF_SERVER_ERROR});
    }
  };

  const handleUserDetails = async () => {
    const userDetails = await fetchUserDetails();
    if (userDetails != null) {
      setUserName(userDetails.userName);
    } else {
      handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
          ClientMetricsCodesEnum.USER_DETAILS_SERVER_ERROR);
      setServerError({isError: true, errorCode: ClientMetricsCodesEnum.USER_DETAILS_SERVER_ERROR});
    }
  };

  const onSubmit = (pin: string, reenterPin: string): Promise<boolean> => {
    return handlePinRegistration(pin, reenterPin, csrfToken, publicKey);
  };

  return (
    <div>
      {serverError.isError ?
        <InternalServerError errorCode={serverError.errorCode} /> :
        <PinInput userName={userName} onSubmit={onSubmit}/>
      }
    </div>
  );
};

export default PinRegistration;
