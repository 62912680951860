import React, {useState, useRef} from 'react';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import CenteredContainer from '../../components/Layout/CenteredContainer';
import TacDisplay from '../../components/TacDisplay/TacDisplay';
import {handleTacGeneration} from '../../handlers/TacAndPin/TacGenerationHandlers';
import {Icon} from '@amzn/awsui-components-react';
import {useReactToPrint} from 'react-to-print';
import {useHotkeys} from 'react-hotkeys-hook';
import TacPrintSuccessAutoLogout from '../../components/Alerts/TacPrintSuccessAutoLogout';
import FailureAlert from '../../components/Alerts/FailureAlert';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';
import {ClientMetricsCodesEnum} from '../../utils/ClientMetricsCodes';

/* eslint-disable @typescript-eslint/no-var-requires */
const qrCodeIcon = require('../../assets/images/qrCodeIcon.svg').default;

interface TacGenerationProps {
  readonly userName: string;
  readonly csrfToken: string;
}

const TacGeneration = (props: TacGenerationProps) => {
  // Used by useReactToPrint hook
  const tacDisplayRef = useRef(null);
  // Used by hot key
  const [showCopyBtn, setShowCopyBtn] = useState<boolean>(false);

  const [tac, setTac] = useState<string>('');
  const [tacError, setTacError] = useState<boolean>(false);
  const [printSuccess, setPrintSuccess] = useState<boolean>(false);
  const [tacLoading, setTacLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState<boolean>(false);
  const bundle = useAuthgardBundle();

  const printTac = useReactToPrint({
    content: () => tacDisplayRef.current,
    onBeforePrint: () => setPrintLoading(true),
    onAfterPrint: () => {
      setPrintLoading(false);
      setPrintSuccess(true);
    },
  });

  const generateTac = async () => {
    setTacLoading(true);
    const tac = await handleTacGeneration(props.csrfToken);
    setTacLoading(false);
    if (tac == null) {
      setTacError(true);
    } else {
      setTac(tac);
    }
  };

  // TODO: Remove after UAT and POC
  useHotkeys('shift+3', () => setShowCopyBtn(((prevState) => !prevState)));

  let alert;
  if (tacError) {
    alert = <FailureAlert
      headerId="tac_generation_failure_title"
      dataId="tac-generation-failure"
      messageId="tac_generation_failure_description"
      errorCode={ClientMetricsCodesEnum.TAC_GENERATION_SERVER_ERROR}
    />;
  } else if (printSuccess) {
    alert = <TacPrintSuccessAutoLogout/>;
  }

  const header: JSX.Element =
      <Header variant="h1">
        {bundle.getMessage('registration_title',
            {userName: props.userName},
        )}
      </Header>;

  return (
    <div>
      <SpaceBetween size="l">
        {alert}
        {!alert && <CenteredContainer header={header}>
          <SpaceBetween direction='vertical' alignItems='center' size='xs'>
            {tac == '' ?
              <>
                <p>
                  {bundle.getMessage('generate_tac_description')}
                </p>
                <Button
                  loading={tacLoading}
                  onClick={generateTac}
                  variant="primary"
                  iconSvg={<img src={qrCodeIcon}/>}
                >
                  {bundle.getMessage('generate_tac_title_generate')}
                </Button>
              </> :
              <>
                <div ref={tacDisplayRef} style={{marginTop: 20}}>
                  <SpaceBetween direction="vertical" alignItems="center" size="l">
                    <TacDisplay tac={tac}/>
                  </SpaceBetween>
                </div>
                <p>
                  <SpaceBetween direction="horizontal" size="xxs">
                    <Icon name="status-info"/>
                    {bundle.getMessage('generate_tac_print_description')}
                  </SpaceBetween>
                </p>
              </>
            }
            {showCopyBtn &&
              <Button
                iconName="copy"
                onClick={() => {
                  navigator.clipboard.writeText(tac);
                }}
              >
                Copy TAC
              </Button>
            }
            {tac !== '' &&
              <Button
                onClick={printTac}
                loading={printLoading}
                variant="primary"
              >
                {bundle.getMessage('generate_tac_title_print')}
              </Button>
            }
          </SpaceBetween>
        </CenteredContainer>}
      </SpaceBetween>
    </div>
  );
};

export default TacGeneration;
