import React from 'react';
import {Alert} from '@amzn/awsui-components-react';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';
import {ErrorAlertProps} from '../../utils/ErrorTypes';

const AuthenticationFailure: React.FC<ErrorAlertProps> = ({errorCode}) => {
  const bundle = useAuthgardBundle();

  const header = errorCode ?
    `${errorCode}: ${bundle.getMessage('authentication_failure_title')}` :
    bundle.getMessage('authentication_failure_title');

  return (
    <Alert
      statusIconAriaLabel="Error"
      type="error"
      data-testid="authentication-failure"
      header={header}
    >
      {bundle.getMessage('authentication_failure_description')}
    </Alert>
  );
};

export default AuthenticationFailure;
