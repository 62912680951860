import React, {useEffect, useState} from 'react';
import {fetchAuthenticationCsrfToken} from '../../handlers/Common/CsrfHandlers';
import {fetchAuthnRuleId} from '../../handlers/Common/UserDetailsHandler';
import TacAndPinInput from '../../components/TacAndPinInput/TacAndPinInput';
import {handleTacAndPinAuthentication} from '../../handlers/TacAndPin/TacAndPinAuthHandlers';
import {fetchIdentityPublicKey} from '../../handlers/Common/EncryptionPinHandlers';
import InternalServerError from '../../components/Alerts/InternalServerError';
import {isLocalEnv} from '../../utils/envHelper';
import {isMobile} from 'react-device-detect';
import {applyDensity, Density} from '@amzn/awsui-global-styles';
import {useLocation, useNavigate} from 'react-router';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';
import {additionalAuthnMethodsAllowed} from '../../utils/AuthnRules';
import {handleClientSideMetrics} from '../../handlers/Common/ClientMetricsHandler';
import {ClientMetricsAttributesEnum, ClientMetricsCodesEnum} from '../../utils/ClientMetricsCodes';

const TacAndPinAuthentication = () => {
  const bundle = useAuthgardBundle();
  const navigate = useNavigate();
  const location = useLocation();
  const [csrfToken, setCsrfToken] = useState<string>(location.state?.csrfToken);
  const [authnRuleId, setAuthnRuleId] = useState<string>(location.state?.authnRuleId);
  const [additionalLoginOptionsAllowed, setAdditionalLoginOptionsAllowed] = useState<boolean>(false);
  const [publicKey, setPublicKey] = useState<CryptoKey>();
  const [serverError, setServerError] =
    useState<{isError: boolean, errorCode: string}>({isError: false, errorCode: ''});
  const requestId = new URLSearchParams(window.location.search).get('request_id') || location.state?.requestId;

  useEffect(() => {
    document.title = bundle.getMessage('authentication_document_title');

    if (!isLocalEnv) {
      handlePublicKey();
      handleCsrf();
    }
    handleAuthnRuleConfigs();

    if (isMobile) {
      applyDensity(Density.Compact);
    }
  }, []);

  const handleCsrf = async () => {
    const token = await fetchAuthenticationCsrfToken();
    if (token != '') {
      setCsrfToken(token);
    } else {
      handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
          ClientMetricsCodesEnum.CSRF_SERVER_ERROR);
      setServerError({isError: true, errorCode: ClientMetricsCodesEnum.CSRF_SERVER_ERROR});
    }
  };

  const handlePublicKey = async () => {
    const key = await fetchIdentityPublicKey();
    if (key != undefined) {
      setPublicKey(key);
    } else {
      handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
          ClientMetricsCodesEnum.PIN_JWKS_SERVER_ERROR);
      setServerError({isError: true, errorCode: ClientMetricsCodesEnum.PIN_JWKS_SERVER_ERROR});
    }
  };

  const handleAuthnRuleConfigs = async () => {
    if (!authnRuleId) {
      const fetchedRuleId = fetchAuthnRuleId();
      setAuthnRuleId(fetchedRuleId);
      setAdditionalLoginOptionsAllowed(additionalAuthnMethodsAllowed(fetchedRuleId));
    } else {
      setAdditionalLoginOptionsAllowed(additionalAuthnMethodsAllowed(authnRuleId));
    }
  };

  const onBack = () => {
    navigate('/authentication/authenticator-selection/',
        {state: {requestId: requestId, csrfToken: csrfToken, authnRuleId: authnRuleId}});
  };

  const onSubmit = (pin: string, tac: string) => {
    return handleTacAndPinAuthentication(pin, tac, csrfToken, publicKey);
  };

  return (
    <div>
      {serverError.isError ?
        <InternalServerError errorCode={serverError.errorCode} /> :
        <TacAndPinInput
          onSubmit={onSubmit}
          onBack={onBack}
          additionalLoginOptionsAllowed={additionalLoginOptionsAllowed}
        />
      }
    </div>
  );
};

export default TacAndPinAuthentication;
