import React, {useEffect, useState} from 'react';
import Header from '@amzn/awsui-components-react/polaris/header';
import ResponsiveContentLayout from '../../components/Layout/ResponsiveContentLayout';
import Button from '@amzn/awsui-components-react/polaris/button';
import CenteredContainer from '../../components/Layout/CenteredContainer';
import {isMobile} from 'react-device-detect';
import {fetchAuthenticationCsrfToken} from '../../handlers/Common/CsrfHandlers';
import InternalServerError from '../../components/Alerts/InternalServerError';
import {isLocalEnv} from '../../utils/envHelper';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';
import {useLocation, useNavigate} from 'react-router-dom';
import {fetchAuthnRuleId, handleUserAuthnSelection} from '../../handlers/Common/UserDetailsHandler';
import {useLocalizationContext} from '@amzn/react-arb-tools';
import {handleClientSideMetrics} from '../../handlers/Common/ClientMetricsHandler';
import {ClientMetricsAttributesEnum, ClientMetricsCodesEnum} from '../../utils/ClientMetricsCodes';

const AuthenticationUserTypeSelectionPage = () => {
  const bundle = useAuthgardBundle();
  const navigate = useNavigate();
  const location = useLocation();
  const [csrfToken, setCsrfToken] = useState<string>(location.state?.csrfToken);
  const [authnRuleId, setAuthnRuleId] = useState<string>(location.state?.authnRuleId);
  const [serverError, setServerError] =
    useState<{isError: boolean, errorCode: string}>({isError: false, errorCode: ''});
  const locale = useLocalizationContext().localizationContext.getLocale();

  const requestId = new URLSearchParams(window.location.search).get('request_id') || location.state?.requestId;

  useEffect(() => {
    if (!isLocalEnv) {
      handleCsrf();
    }
    handleAuthnRuleConfigs();
  }, []);

  const updateUserAuthnSelection = async (authnPath:string, authnMethod:string, isApiDomain:boolean) => {
    const verifyUserAuthnUpdate = await handleUserAuthnSelection(authnMethod, csrfToken);
    if (verifyUserAuthnUpdate) {
      if (isApiDomain) {
        window.location.assign(`https://api.${window.location.hostname}${authnPath}`);
      } else {
        navigate(authnPath, {state: {requestId: requestId, csrfToken: csrfToken, authnRuleId: authnRuleId}});
      }
    } else {
      handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
          ClientMetricsCodesEnum.USER_DETAILS_SERVER_ERROR);
      setServerError({isError: true, errorCode: ClientMetricsCodesEnum.USER_DETAILS_SERVER_ERROR});
    }
  };
  const handleAuthnRuleConfigs = async () => {
    if (!authnRuleId) {
      const fetchedRuleId = fetchAuthnRuleId();
      setAuthnRuleId(fetchedRuleId);
    }
  };

  const handleCsrf = async () => {
    if (csrfToken) return;
    const token = await fetchAuthenticationCsrfToken();
    if (token != '') {
      setCsrfToken(token);
    } else {
      handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
          ClientMetricsCodesEnum.CSRF_SERVER_ERROR);
      setServerError({isError: true, errorCode: ClientMetricsCodesEnum.CSRF_SERVER_ERROR});
    }
  };

  const header: JSX.Element =
      <Header
        variant="h1"
        description={bundle.getMessage('authentication_selection_page_description')}
      >
        {bundle.getMessage('authentication_selection_page_title',
            {title: 'authentication_selection_page_title'},
        )}
      </Header>;

  return (
    <ResponsiveContentLayout>
      {serverError.isError ?
      <InternalServerError errorCode={serverError.errorCode} /> :
      <CenteredContainer header={header}>
        <div data-css={isMobile ? 'mobile-col' : 'col'}>
          <Button
            variant='primary'
            fullWidth
            onClick={() => updateUserAuthnSelection(
                `/badge/v1/validateOTPAndPin?request_id=${requestId}&ui_locales=${locale}`,
                'badgeAndPinV1',
                true)
            }
          >
            {bundle.getMessage('authentication_1p')}
          </Button>
          <Button
            variant='primary'
            fullWidth
            onClick={() => updateUserAuthnSelection(
                `/authentication/fido2`,
                'fido2',
                false)
            }
          >
            {bundle.getMessage('authentication_3p')}
          </Button>
        </div>
      </CenteredContainer>
      }
    </ResponsiveContentLayout>

  );
};

export default AuthenticationUserTypeSelectionPage;
