import React from 'react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';
import {ErrorAlertProps} from '../../utils/ErrorTypes';

const RegistrationFailure: React.FC<ErrorAlertProps> = ({errorCode}) => {
  const bundle = useAuthgardBundle();

  const header = errorCode ?
    `${errorCode}: ${bundle.getMessage('registration_failure_title')}` :
    bundle.getMessage('registration_failure_title');

  return (
    <Alert
      statusIconAriaLabel="Error"
      type="error"
      data-testid="registration-failure"
      header={header}
    >
      {bundle.getMessage('registration_failure_description')}
    </Alert>
  );
};

export default RegistrationFailure;
